import {
  api,
  createCrudService,
  responseToOptionsInContext
} from '@cargill/shared'

const service = createCrudService(
  '/api/controltower/takeUpLotReportInputMaster',
  api,
  {
    importLogUrl: '/api/controltower/takeUpLotReportInputMaster/importLog'
  }
)

// eslint-disable-next-line no-unused-vars
service.processValidationContext = (component, ctx, meta) => {
  responseToOptionsInContext(ctx)
}

export default service
