import {
  api,
  createCrudService,
  handleError,
  responseToOptions,
  responseToOptionsInContext,
  i18n,
  notify
} from '@cargill/shared'
import _ from 'lodash'

const baseUrl = '/api/controltower/takeUpLotReportInput'
const service = createCrudService(baseUrl, api, {
  importLogUrl: `${baseUrl}/importLog`,
  masterName: 'bookId'
})

service.processValidationContext = (component, ctx, meta) => {
  responseToOptionsInContext(ctx)
  const fieldsById = _.keyBy(meta.fields, (field) => field.id)

  Object.keys(ctx.ginOptionsByProducer).forEach((x) => {
    ctx.ginOptionsByProducer[x] = responseToOptions(ctx.ginOptionsByProducer[x])
  })
  fieldsById.gin.options = async ({ itemData }) => {
    const bookId = itemData?.bookId
    if (bookId == null) {
      return []
    }
    const producerId = ctx.producerByBook[bookId]
    return ctx.ginOptionsByProducer[producerId] ?? []
  }

  fieldsById.deliveryProducer.options = ctx.producerOptions
}

service.deleteByBook = async (bookId) => {
  try {
    const response = await api.delete(`${baseUrl}/deleteByBook/${bookId}`)
    return response
  } catch (e) {
    handleError(e)
    throw e
  }
}

service.calculateUsBucket = async (id) => {
  try {
    const response = await api.put(`${baseUrl}/calculateUsBucket/${id}`)
    if (_.isEmpty(response.data.errorMsg)) {
      const message = i18n.t(
        'controlTower.pages.takeUpLotReportInputMaster.messages.calculateUsBucketSuccessMsg',
        response.data
      )
      notify.success({
        title: message
      })
    } else {
      notify.error({
        title: i18n.t(response.data.errorMsg)
      })
    }
    return response.data
  } catch (e) {
    handleError(e)
  }
}

service.calculateUsBucketByBook = async (bookId) => {
  try {
    const response = await api.put(
      `${baseUrl}/calculateUsBucketByBook/${bookId}`
    )
    if (response.data.errorsQty > 0) {
      response.data.logTitle = i18n.t(
        'controlTower.pages.takeUpUsBucketCalculatorReportLog.title'
      )
      const message = i18n.t(
        'controlTower.pages.takeUpLotReportInputMaster.messages.calculateUsBucketByBookErrorMsg',
        response.data
      )
      notify.error({
        title: message
      })
    } else if (response.data.hviStandbyQty > 0) {
      const message = i18n.t(
        'controlTower.pages.takeUpLotReportInputMaster.messages.calculateUsBucketByBookWarningMsg',
        response.data
      )
      notify.warning({
        title: message
      })
    } else {
      const message = i18n.t(
        'controlTower.pages.takeUpLotReportInputMaster.messages.calculateUsBucketByBookSuccessMsg',
        response.data
      )
      notify.success({
        title: message
      })
    }
    return response.data
  } catch (e) {
    handleError(e)
  }
}

service.hasLotWithRejectedTakeUpPremiumDiscountByBook = async (bookId) => {
  try {
    const response = await api.get(
      `${baseUrl}/hasLotWithRejectedTakeUpPremiumDiscountByBook/${bookId}`
    )
    return response.data
  } catch (e) {
    handleError(e)
  }
}

service.hasLotWithRejectedTakeUpPremiumDiscountByFilter = async () => {
  try {
    const filter = _.isEmpty(service.lastAppliedFilter)
      ? ''
      : `?${service.lastAppliedFilter}`
    const response = await api.get(
      `${baseUrl}/hasLotWithRejectedTakeUpPremiumDiscountByFilter${filter}`
    )
    return response.data
  } catch (e) {
    handleError(e)
  }
}

service.hasLotWithRejectedTakeUpPremiumDiscount = async (ids) => {
  try {
    const response = await api.post(
      `${baseUrl}/hasLotWithRejectedTakeUpPremiumDiscount`,
      ids
    )
    return response.data
  } catch (e) {
    handleError(e)
  }
}

export default service
